import React, { useEffect, useState } from "react";
import { ICityPageProps } from "./CityPageProps";
import { HotelListing, PopularAttractions, SubTitle2 } from "./CityPageHelpers";
import { graphql } from "gatsby";
import Axios from "axios";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { searchSelector, setSearch } from "../../redux/slices/Search/search";
import { coreGet } from "../../services/core";
import { getParagraph } from "../../utils/paragraphHelpers";
import Layout from "../../components/global/Layout/Layout";
import { Container, Row } from "react-bootstrap";
import SEO from "../../components/global/SEO/SEO";
import FeaturesTabsCity from "../../components/common/FeaturesTabsCity/FeaturesTabsCity";
import Title2 from "../../components/global/Title2/Title2";
import DeepLinking from "../../components/common/DeepLinking/DeepLinking";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import { Constants } from "../../@types/Constants";
import IParagraph from "../../@types/IParagraph";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { useWebFramed } from "../../hooks/useWebFramed";
import BreadCrumb from "../../components/common/Breadcrumb/Breadcrumb";
import { extractBrandFromUrl } from "../../utils/helpers";
import { guestServiceProvider } from "../../services/ServiceProvider";
import InterestLinks from "../../components/common/IntrestLinks/IntrestLinks";
import useBrandBias from "../../hooks/useBrandBias";
///import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";

const searchDateFormat = "yyyy-MM-dd";

const CityPage = (props: ICityPageProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const search = useAppSelector(searchSelector);
  const isWebFramed = useWebFramed();
  const city = props.data.locationCity;
  const coordinates = city?.field_coordinates;
  const SITE_ID = 31;
  const [points, setPoints] = useState([]);
  const [distance, setDistance] = useState(10);
  const [interestLinks, setIntrestLinks] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const location = props.location;
  const brandsArr = extractBrandFromUrl(location.search);
  const cityBrandCode =
    location.state && location.state.brand && location.state.brand.brand_code;
  const cityName = city ? city.name : "";
  const hotelSearchFilters = props.data.allRlhsite.edges.length
    ? props.data.allRlhsite.edges[0].node.field_filter_selected
    : [];
  const hotelReferences =
    city && city?.hotel_reference && city?.hotel_reference.length
      ? city?.hotel_reference
      : [];
  const title = city?.field_sonesta_meta_title;
  const description = city?.field_sonesta_meta_description;
  const countryCode = city?.field_address?.country_code;
  useBrandBias();
  useEffect(() => {
    if (coordinates !== null) {
      const payload = {
        latitude: coordinates?.lat,
        longitude: coordinates?.lon,
        searchString: cityName,
        searchType: "city",
        distance: 50,
        country: countryCode,
        searchTypeGapi: "locality",
        // rooms: [new Room()],
        // checkin: search.checkin,
        // checkout: search.checkout,
        // discount: search.discount,
        // promotionCode: search.promotionCode,
        // groupCode:  search.groupCode
      };

      dispatch(setSearch(payload));
    }
    addPageTypeInGTMDataLayer("search");
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    (async () => {
      if (!didCancel && coordinates?.lat && coordinates?.lon && distance) {
        setIsLoading(true);
        let url = `/location-proximity?coordinates[value]=${distance}`;
        url += `&coordinates[source_configuration][origin][lat]=${coordinates?.lat}&coordinates[source_configuration][origin][lon]=${coordinates?.lon}`;
        url += "&type[]=point_of_interest";
        if (!didCancel) {
          //Use Location Proximity Response
          const guestService = await guestServiceProvider();
          const response = await guestService.getLocationProximity(
            distance,
            coordinates?.lat,
            coordinates?.lon
          );
          if (response.searchLocations.results) {
            const filteredPoints = response.searchLocations.results.filter(
              (location) => location.locationType === "point_of_interest"
            );
            setPoints(filteredPoints);
            setIsLoading(false);
          }
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for points of interest.");
    };
  }, [distance]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    (async () => {
      if (!didCancel) {
        let apiUrl = `/get_location_interests?_format=json&siteId=${31}`;
        const uri = location.pathname.replace(/^\/+/, "");
        apiUrl += `&url=${uri}`;
        const data = await coreGet(apiUrl, source);
        if (!didCancel) {
          const resData = data?.data?.interests || [];
          setIntrestLinks(resData);
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for points of interest.");
    };
  }, []);
  const filterSonestaParagraphs = (paragraph: {
    relationships: { field_sites: { machine_name: string } };
  }) => {
    return paragraph?.relationships?.field_sites
      ? paragraph?.relationships.field_sites.machine_name === "sonesta"
        ? true
        : false
      : false;
  };
  const siteParagraphs = city?.relationships.paragraphs?.filter(
    filterSonestaParagraphs
  );
  const paragraphs = siteParagraphs ? siteParagraphs.map(getParagraph) : [];

  const belowSearchSiteParagraphs =
    city?.relationships.paragraphsBelowSearch?.filter(filterSonestaParagraphs);
  const belowSearchParagraphs = belowSearchSiteParagraphs
    ? belowSearchSiteParagraphs.map(getParagraph)
    : [];

  const handleSetDistance = (newDistance: number) => {
    setDistance(newDistance);
  };

  // Filter Feature Based on SiteID
  const sonestaFeatures =
    props.data.locationCity.relationships.field_features.filter((feature) =>
      feature.relationships?.field_sites
        ? feature.relationships.field_sites.machine_name === "sonesta"
          ? true
          : false
        : false
    );

  const _pageBanner = paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  const pageIntro = paragraphs.filter((p: IParagraph) => {
    return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_INTRO : false;
  });
  // Check if code is running on the client side
  const isClient = typeof window !== "undefined";

  //since we need to call api in client browser for relevance and sort, remvoing the graphql result
  const hotelReferencesProp = isClient
    ? {}
    : { hotelReferences: hotelReferences };
  return (
    <Layout showMerchandisingBlock={countryCode == "US"}>
      <SEO
        title={title || `Hotels in ${cityName}`}
        noIndex={city?.field_no_index}
        noFollow={city?.field_seo_nofollow}
        description={description || ""}
        includeLeaflet
        koddiTitle="CityPage"
      />
      {_pageBanner}
      {!isWebFramed && <ParagraphLayout {...paragraphs} />}
      <HotelListing
        city={city}
        sortBy={cityBrandCode ? "brand" : ""}
        location={props.location}
        hotelSearchFilters={hotelSearchFilters}
        isPageIntroAvailable={pageIntro.length > 0 ? true : false}
        brandsInUrl={brandsArr}
        {...hotelReferencesProp}
      />
      <DeepLinking location={location} pageName={"City"} />
      {!isWebFramed && <ParagraphLayout {...belowSearchParagraphs} />}
      {sonestaFeatures !== null && sonestaFeatures.length ? (
        <Container
          className="bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 mb-4 mb-lg-5 position-relative"
          fluid
        >
          <a
            className="anchor-target visually-hidden"
            id="HotelDetails"
            aria-hidden={true}
          />
          <Container fluid="sm" className="p-0">
            <Row>
              <SubTitle2 id={"about-the-city"}>
                {city?.field_section_header || "About The City"}
              </SubTitle2>
              <Title2 id={"attractions-location"}>
                {city?.field_attraction_tab_header || "Attractions"}
              </Title2>
            </Row>

            <FeaturesTabsCity features={sonestaFeatures} />
          </Container>
        </Container>
      ) : null}
      {!isWebFramed && (
        <>
          {" "}
          <PopularAttractions
            points={points}
            isLoading={isLoading}
            city={city}
            coordinates={coordinates}
            distance={distance}
            setDistance={handleSetDistance}
          />
          <div className={"bg-light pt-4 pb-4 position-relative"}>
            {interestLinks.length > 0 ? (
              <InterestLinks
                InterestLinks={interestLinks}
                cityName={cityName.split(", ")[0]}
              />
            ) : null}
          </div>
          <div className={"bg-light pt-4 pb-4 pb-lg-5 position-relative"}>
            <div className={"container-sm"}>
              <BreadCrumb
                cityName={cityName}
                location={props.location}
                activeTitle=""
                countryCode={countryCode}
                statePath={props.data?.locationState}
                cityParentAlias={
                  props.data?.locationCity?.fields.path?.parent_alias
                }
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};
{
  /*TODO: Add ParagraphPhotoGallery*/
}
export const query = graphql`
  query ($slug: String!, $statePath: String) {
    locationCity(fields: { path: { alias: { eq: $slug } } }) {
      name
      field_sonesta_meta_description
      field_sonesta_meta_title
      field_no_index
      field_seo_nofollow
      field_address {
        country_code
        administrative_area
      }
      path {
        alias
      }
      field_coordinates {
        lat
        lon
      }
      fields {
        path {
          alias
          parent_alias
        }
      }
      field_attraction_tab_header
      field_section_header
      hotel_reference {
        name
        brand {
          code
          logoUrl
          name
        }
        coordinates {
          latitude
          longitude
        }
        address {
          locality
          streetAddress1
          postalCode
          cityName
          country
          region
        }
        crsHotelCode
        crsName
        gallery
        galleryImages {
          alt
          url
        }
        hotelCode
        hotelId
        phone
        rangeInMilesFromOrigin
        url
        amenities
        amenityLabels
        tagline
        tags
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphPhotoGallery
          ...ParagraphFrequentlyAskedQuestions
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageIntro
          ...ParagraphMediaCta
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphPhotoGallery
          ...ParagraphFrequentlyAskedQuestions
        }
        field_features {
          field_feature_tab_title
          field_feature_title
          field_feature_description {
            value
          }
          field_feature_image {
            alt
          }
          relationships {
            field_sites {
              machine_name
            }
            field_feature_image {
              url
            }
            field_sub_feature {
              field_feature_description {
                value
              }
              field_feature_tab_title
              field_feature_title
              field_feature_image {
                alt
              }
              relationships {
                field_feature_image {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
    allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
      edges {
        node {
          field_filter_selected
        }
      }
    }
    locationState: nodeState(path: { alias: { eq: $statePath } }) {
      path {
        alias
      }
    }
  }
`;
export default CityPage;
